var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.value.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"拟投标价(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bidPriceProposed',
            {
              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'bidPriceProposed',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"招标控制价(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bidPriceLimit',
            {
              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'bidPriceLimit',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"投标控制预算(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bidBudget',
            {
              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'bidBudget',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.value.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"主要内容","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'remark',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}],attrs:{"auto-size":{ minRows: 5 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v("《投标控制预算书》")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }