
<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <!-- 从文件列表打开，只能展示当前表单 -->
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :process-disable="processDisable"
                        :url="imgUrl"
                        :width="imgWidth"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <BudgetComment v-show="activeKey === '0'"
                     ref="budgetComment"
                     :value="pData"></BudgetComment>
      <BudgetApproval v-show="activeKey === '1' "
                      ref="budgetApproval"
                      :value="
                      pData"></BudgetApproval>
      <MeetComment v-show="activeKey === '2' && hasRight"
                   ref="meetComment"
                   :value="pData"></MeetComment>
      <RiskComment v-show="activeKey === '3' && hasRight"
                   ref="riskComment"
                   :value="pData"></RiskComment>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import BudgetComment from "./components/BudgetComment";
import BudgetApproval from "./components/BudgetApproval";
import MeetComment from "./components/MeetComment";
import RiskComment from "./components/RiskComment";

import { fetchDetail } from "@/api/epc";
import { fetchDetailByPid } from "@/api/epc/budget";

export default {
  name: "projBudegt",
  components: {
    ExportDownload,
    BudgetApproval,
    BudgetComment,
    MeetComment,
    RiskComment,
  },
  data() {
    return {
      imgUrl: "",
      imgWidth:'100%',
      processDisable: false,

      activeKey: "0",
      tabList: [
        { key: "0", name: "投标控制预算审核意见表", disabled: false },
        { key: "1", name: "投标控制预算审批表", disabled: false },
        { key: "2", name: "风控意见", disabled: false },
        { key: "3", name: "经决会意见", disabled: false },
      ],
      hasRight: false, //判断是否可新增

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},
      status: false,

      isView: false,

      hid: "",
    };
  },
  watch: {
    activeKey: {
      handler(newV) {
        console.log(newV);
        if (newV === "0") {
          this.processDisable = false;
          this.imgWidth='50%'
          this.imgUrl = require("@/assets/epc/approvalFlow/1-1.png");
        } else if (newV === "1") {
          this.processDisable = false;
          this.imgWidth='100%'
          this.imgUrl = require("@/assets/epc/approvalFlow/1-2.png");
        } else {
          this.processDisable = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, isView, id, able, hid } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid || "";
    this.isView = Boolean(isView) || false;
    this.id = id || "";
    this.hid = hid || "";

    this.getProjectInfo();
    //从编辑进入页面，设置非活跃状态的tab不可用
    // 或若投标控制预算审核意见表审核过，可用，否则不可用
    if (id || able === "false") {
      this.setTab();
    } else {
      // 判断投标控制预算审批表是否审核过，审核过则风控意见及经决会意见可用，否则不可用
      this.setUnableTab();
    }
  },

  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    setUnableTab() {
      fetchDetailByPid({ pid: this.pid, type: "epc_budget" }).then((res) => {
        let status = res.status === "approved" || res.status === "not_review";

        if (!status) {
          // 未审核，禁用tab，
          this.tabList.forEach((item) => {
            if (item.key === "2" || item.key === "3") {
              item.disabled = true;
            }
          });
        } else {
          this.hasRight = true;
        }
      });
    },

    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.budgetComment.download();
          break;
        case "1":
          this.$refs.budgetApproval.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.budgetComment.exportForm();
          break;
        case "1":
          this.$refs.budgetApproval.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
