import request from '../request'
import download from '../download'

// 投标控制预算编制相关
export function add(data) {
  return request({
      url: '/market-service/epc/project-start/budget/add',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/project-start/budget/update',
      method: 'post',
      data,
  })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/project-start/budget/query/${params.id}`,
  })
}

export function fetchDetailByPid(params) {
  return request({
      url: `/market-service/epc/project-start/budget/queryByPid`,
      params
  })
}

// 下载投标控制预算编制模版
export function downloadTemplate() {
  return download({
      url: '/market-service/epc/project-start/budget/export/template',
  })
}
// 导出
export function exportFile(params) {
  return download({
      url: `/market-service/epc/project-start/budget/export/${params.id}`,
  })
}

