<template>
  <a-form
    :form="form"
    @submit="handleSubmit"
    :label-col="{ span: 8 }"
    :wrapper-col="{ span: 12 }"
    :colon="false"
  >
    <a-row>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item label="当前项目" class="default-highlight">
          <a-input :disabled="true" :placeholder="value.designCode"></a-input>
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item label="拟投标价(万元)">
          <a-input-number
            :formatter="$inputNumberFormat"
            style="width: 100%"
            v-decorator="[
              'bidPriceProposed',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]"
          ></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item label="招标控制价(万元)">
          <a-input-number
            :formatter="$inputNumberFormat"
            style="width: 100%"
            v-decorator="[
              'bidPriceLimit',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]"
          ></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :lg="12" :md="12" :sm="24">
        <a-form-item label="投标控制预算(万元)">
          <a-input-number
            :formatter="$inputNumberFormat"
            style="width: 100%"
            v-decorator="[
              'bidBudget',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]"
          ></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :lg="24" :md="24" :sm="24">
        <a-form-item
          label="工程名称"
          class="default-highlight"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-input :disabled="true" :placeholder="value.projectName" />
        </a-form-item>
      </a-col>
      <a-col :lg="24" :md="24" :sm="24">
        <a-form-item
          label="投标控制预算简述"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-textarea
            :auto-size="{ minRows: 5 }"
            v-decorator="[
              'remark',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="24" :md="24" :sm="24">
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span slot="label" class="">附件</span>
          <div class="link-list">
            <a-checkbox-group
              :value="selectedFileList"
              @change="onSelectFile"
              v-if="fileList.length !== 0"
            >
              <div class="link" v-for="item in fileList" :key="item.id">
                <a-checkbox :value="item.id">
                  <a target="_blank" download :href="item.completePath">{{
                    item.name
                  }}</a>
                </a-checkbox>
              </div>
            </a-checkbox-group>
            <div style="color: #ccc" v-else-if="fileList.length === 0 && id">
              无
            </div>
            <div style="color: #ccc" v-else>《投标控制预算书》</div>

            <div class="control-bar">
              <FileUpload @uploaded="uploaded">
                <div class="control">
                  <a-icon type="upload" />
                </div>
              </FileUpload>

              <div class="control" @click="deleteFile">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <div class="center">
      <a-space>
        <a-button @click="$close($route.path)">关闭</a-button>
        <a-button htmlType="submit" type="primary" v-if="!isView"
          >提交</a-button
        >
      </a-space>
    </div>
  </a-form>
</template>

<script>
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetailByPid,
  exportFile,
  downloadTemplate,
} from "@/api/epc/budget.js";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_budget",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //表的id

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, isView, hid } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;
    this.hid = hid || "";

    if (this.pid) {
      // 调取投标控制预算审核意见表,获取投标相关数据
      this.getBidData();
      this.getDetailByPid();
    }
  },
  methods: {
    getBidData() {
      fetchDetailByPid({ pid: this.pid, type: "epc_budget_review" }).then(
        (res) => {
          this.form.setFieldsValue({
            bidPriceProposed: res.bidPriceProposed,
            bidPriceLimit: res.bidPriceLimit,
            bidBudget: res.bidBudget,
          });
        }
      );
    },

    getDetailByPid() {
      fetchDetailByPid({ pid: this.pid, type: this.stage })
        .then((res) => {
          this.id = res.id;
          this.hid = res.historyId;

          this.form.setFieldsValue({
            bidPriceProposed: res.bidPriceProposed,
            bidPriceLimit: res.bidPriceLimit,
            remark: res.remark,
            bidBudget: res.bidBudget,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.value.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.value.projectName,
              designCode: this.value.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.getDetailByPid();
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.value.projectName,
              designCode: this.value.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
