<template>
  <a-form :form="form"
          @submit="handleSubmit"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
          :colon="false">
    <a-row>
      <a-col :lg="12"
             :md="12"
             :sm="24">
        <a-form-item label="当前项目"
                     class="default-highlight">
          <a-input :disabled="true"
                   :placeholder="value.designCode"></a-input>
        </a-form-item>
      </a-col>

      <a-col :lg="24"
             :md="24"
             :sm="24">
        <a-form-item label="工程名称"
                     class="default-highlight"
                     :label-col="{ span: 4 }"
                     :wrapper-col="{ span: 18 }">
          <a-input :disabled="true"
                   :placeholder="value.projectName" />
        </a-form-item>
      </a-col>

      <a-col :lg="24"
             :md="24"
             :sm="24">
        <a-form-item :label-col="{ span: 4 }"
                     :wrapper-col="{ span: 18 }">
          <span slot="label"
                class="">附件</span>
          <div class="link-list">
            <a-checkbox-group :value="selectedFileList"
                              @change="onSelectFile"
                              v-if="fileList.length !== 0">
              <div class="link"
                   v-for="item in fileList"
                   :key="item.id">
                <a-checkbox :value="item.id">
                  <a target="_blank"
                     download
                     :href="item.completePath">{{
                        item.name
                      }}</a>
                </a-checkbox>
              </div>
            </a-checkbox-group>
            <div style="color:#ccc;"
                 v-else-if="fileList.length === 0 && id">
              无
            </div>
            <div style="color:#ccc;"
                 v-else>
              《风险控制委员会意见》
            </div>

            <div class="control-bar">
              <FileUpload @uploaded="uploaded">
                <div class="control">
                  <a-icon type="upload" />
                </div>
              </FileUpload>

              <div class="control"
                   @click="deleteFile">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <div class="center">
      <a-space>
        <a-button @click="$close($route.path)">关闭</a-button>
        <a-button htmlType="submit"
                  type="primary"
                  v-if="!isView">提交</a-button>
      </a-space>
    </div>
  </a-form>
</template>

<script>
import { add, edit, fetchDetail } from "@/api/epc/budget.js";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      stage: "epc_budget_risk",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //表的id

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, isView, hid } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;
    this.hid = hid || "";

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          console.log(res);
          if (!res.id) return;

          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          // 如果没有id ，则新增
          if (!this.id) {
            add({
              pid: this.pid,
              projectName: this.value.projectName,
              designCode: this.value.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.fileList = [];
            });
          } else {
            edit({
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.value.projectName,
              designCode: this.value.designCode,
              attachments: this.fileList,
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
